import { Component, Fragment, Suspense, lazy } from 'react';
import { Switch, Prompt } from 'react-router-dom';
import { APPLICATION_BASE_PATH } from '../../constants';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  getMember,
  getMemberDevices,
  toggleEditMemberDetails,
  getAssignableDoctors,
  setMemberServiceStatus,
  showNotification,
  getMemberComment,
  updateMemberComment,
  updateMemberCommentText,
  getMemberProfileImage,
  showJiraModal,
  hideJiraModal,
  getMemberJiraIssues,
  getJiraIssueTypes,
  postMemberJiraIssue,
  setJiraIssueType,
  setJiraIssueDescription,
  hideJiraHistoryModal,
  showJiraHistoryModal,
  addManualShipment,
  getScheduledCommunication,
  showMessageModal,
  hideMessageModal,
  sendMemberMessage,
  updateMessageSubject,
  updateMessageBody,
  toggleExportJournalModal,
  getMemberJournalRequest,
  getMemberJournalSuccess,
  getMemberJournalError,
  getMemberChatMessages,
  toggleSparModal,
  getMemberSparData,
  getMemberPaymentHistory,
  getMemberShipmentStatus,
  getMemberState,
  sendMonitorInvoice,
  getMemberMonitors,
  getMonitorSizes,
  updateMemberState,
  getFunnelStatus
} from '../../actions';
import { getEnvironmentPrefix } from '../../utils';
import EditableSelect from '../EditableSelect';
import ContentLoader from '../ContentLoader';
import CanPerform from '../CanPerform';
import { DATE_FORMAT, COMMENT_COLORS, MEMBER_SERVICE_STATUS, CURRENT_ENV } from '../../constants';
import api from '../../api/apiClient';
import LoaderButton from '../LoaderButton';
import ProfileCard from '../ProfileCard';
import LoadingIcon from '../LoadingIcon';
import Modal from '../Modal';
import JiraSupportModalContent from '../JiraSupportModalContent';
import Button from '../Button';
import ErrorFallback from '../ErrorFallback';
import './MemberDetailsView.scss';
import MemberDetailsInformation from './MemberDetailsInformation';
import PrivateRoute from '../PrivateRoute';
import DeactivationFunnel from './DeactivationFunnel';
import Generation from '../Generation';
import ChatProvider from '../../contexts/ChatProvider';
import Chat from '../Chat';
import SkeletonComponent from '../SkeletonComponent';
import PatientDataErasure from './PatientDataErasure';
const MemberMessageEditor = lazy(() => import('./MemberMessageEditor'));

class MemberDetailsView extends Component {
  constructor(props) {
    super(props);

    this.changeCommentColor = this.changeCommentColor.bind(this);
    this.saveNewComment = this.saveNewComment.bind(this);
    this.createJiraIssue = this.createJiraIssue.bind(this);
    this.createShipment = this.createShipment.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);
    this.hideMember = this.hideMember.bind(this);
    this.downloadMemberJournal = this.downloadMemberJournal.bind(this);
    this.showSparData = this.showSparData.bind(this);
    this.convertSparToReadable = this.convertSparToReadable.bind(this);
    this.sendMonitorInvoice = this.sendMonitorInvoice.bind(this);

    this.state = {
      memberUpdates: {},
      caregivers: {},
      hasChanges: false,
      selectedIssueType: null,
      commentColor: null,
      selectedJournalInterval: null
    };
  }

  componentDidMount() {
    this.props.getMember(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberDevices(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberComment(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberJiraIssues(this.props.authToken, this.props.match.params.memberId);
    this.props.getScheduledCommunication(this.props.authToken, this.props.match.params.memberId);
    this.props.getJiraIssueTypes(this.props.authToken);
    this.props.getMemberChatMessages(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberPaymentHistory(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberShipmentStatus(this.props.authToken, this.props.match.params.memberId);
    this.props.getMemberState(this.props.authToken, this.props.match.params.memberId, 'DefaultBottomFunnel');
    this.props.getMemberState(this.props.authToken, this.props.match.params.memberId, 'DeactivationFunnel');
    this.props.getMemberMonitors(this.props.authToken, this.props.match.params.memberId);
    this.props.getMonitorSizes(this.props.authToken, this.props.match.params.memberId);
    this.props.getFunnelStatus(this.props.authToken, this.props.match.params.memberId);
  }

  componentDidUpdate(prevProps) {
    const { currentMember } = this.props.members;
    const { authorities, authority } = this.props;

    if (currentMember && currentMember.guid !== prevProps.members.currentMember.guid) {
      if (currentMember.region && currentMember.responsibleDoctor && authorities.includes(authority.managePatients)) {
        this.props.getAssignableDoctors(this.props.authToken, currentMember.region.id, currentMember.source);
      }

      if (currentMember.profileImage && currentMember.profileImage.id) {
        this.props.getMemberProfileImage(
          this.props.authToken,
          this.props.match.params.memberId,
          currentMember.profileImage.id
        );
      }
    }
  }

  hideMember() {
    const { currentMember } = this.props.members;
    this.props.setMemberServiceStatus(
      this.props.authToken,
      this.props.match.params.memberId,
      MEMBER_SERVICE_STATUS.HIDDEN,
      `${currentMember.givenName} ${currentMember.familyName}`
    );
  }

  changeCommentColor(e) {
    const color = e.target.getAttribute('data-color');
    this.setState({
      commentColor: color
    });

    this.props.updateMemberComment(this.props.authToken, this.props.match.params.memberId, {
      text: this.props.members.memberCommentText,
      color: color ? color.slice(1) : null
    });
  }

  saveNewComment() {
    this.props.updateMemberComment(
      this.props.authToken,
      this.props.match.params.memberId,
      { ...this.props.members.memberComment, text: this.props.members.memberCommentText },
      true
    );
  }

  createJiraIssue() {
    const { jira } = this.props;
    const jiraIssue = {
      project: process.env.REACT_APP_JIRA_PROJECT_KEY || 'ST',
      type: jira.selectedSupportType.name,
      summary: jira.selectedSupportType.name,
      description: jira.issueDescription,
      accumboAdminLink: `https://admin.accumbo.se/app/member/${this.props.match.params.memberId}`,
      accumboMemberGuid: this.props.match.params.memberId
    };
    this.props.postMemberJiraIssue(this.props.authToken, jiraIssue);
  }

  createShipment(shipmentType, reason) {
    return () => {
      this.props.addManualShipment(this.props.authToken, this.props.match.params.memberId, shipmentType, reason);
    };
  }

  sendMessage() {
    const { authToken, match, members } = this.props;
    this.props.sendMemberMessage(authToken, match.params.memberId, members.messageSubject, members.messageBody);
  }

  closeMessageModal() {
    const { messageSubject, messageBody } = this.props.members;

    if (messageSubject.length || messageBody.length) {
      if (window.confirm(I18n.t('member_details.message_discard_alert'))) {
        this.props.hideMessageModal();
      }
    } else {
      this.props.hideMessageModal();
    }
  }

  downloadMemberJournal() {
    this.props.getMemberJournalRequest();

    api
      .getMemberJournal(this.props.authToken, this.props.match.params.memberId, this.state.selectedJournalInterval)
      .then((response) => {
        this.props.getMemberJournalSuccess(response);
        const blob = new Blob([response.response.body], {
          type: 'application/pdf;charset=utf-8'
        });
        saveAs(blob, `Accumbo-journal_${this.props.members.currentMember.personalNumber}.pdf`);
        this.setState({ selectedJournalInterval: null });
      })
      .catch((error) => {
        console.log(error);
        this.props.getMemberJournalError(error);
        this.props.showNotification(I18n.t('notification.export_journal.error'), 'error');
      });
  }

  showSparData() {
    this.props.toggleSparModal();
    this.props.getMemberSparData(this.props.authToken, this.props.match.params.memberId);
  }

  convertSparToReadable(sparObj) {
    if (sparObj === undefined) {
      return;
    }

    const dateRegex = /\d{4}-\d{2}-\d{2}/;

    return Object.getOwnPropertyNames(sparObj).map((name, i) => {
      if (typeof sparObj[name] === 'boolean') {
        return (
          <div key={i}>
            <strong>{name}:</strong> <Translate value={`global.${sparObj[name] ? 'yes' : 'no'}`} />
          </div>
        );
      }

      if (Array.isArray(sparObj[name])) {
        return (
          <div key={i} className={name === 'utlandsadress' && sparObj[name].length ? 'spar-highlight' : ''}>
            <strong>{name}:</strong>
            <ol>
              {sparObj[name].length
                ? sparObj[name].map((item, i) => (
                    <li key={`item-${i}`} className="mb-10">
                      {this.convertSparToReadable(item)}
                    </li>
                  ))
                : '-'}
            </ol>
          </div>
        );
      }

      if (typeof sparObj[name] === 'object' && sparObj[name] !== null) {
        return (
          <div key={i}>
            <strong>{name}</strong>
            {this.convertSparToReadable(sparObj[name])}
          </div>
        );
      }

      if (name === 'avregistreringsorsakKod' && sparObj[name]) {
        return (
          <Fragment key={i}>
            <strong>{name}:</strong>{' '}
            <span className="spar-highlight">
              {sparObj[name]} (<Translate value={`member_details.spar.deregistration_code.${sparObj[name]}`} />)
            </span>
            <br />
          </Fragment>
        );
      }

      return (
        <Fragment key={i}>
          <strong>{name}:</strong>{' '}
          <span>{dateRegex.exec(sparObj[name]) ? dateRegex.exec(sparObj[name])[0] : sparObj[name] || '-'}</span>
          <br />
        </Fragment>
      );
    });
  }

  sendMonitorInvoice() {
    this.props.sendMonitorInvoice(this.props.authToken, this.props.match.params.memberId);
  }

  render() {
    const {
      currentMember,
      fetchingMember,
      memberError,
      memberComment,
      memberCommentText,
      messageSubject,
      messageBody,
      shipmentStatus
    } = this.props.members;

    const { jira, authority } = this.props;

    const currentCommentColor =
      this.state.commentColor || memberComment.color ? this.state.commentColor || `#${memberComment.color}` : null;

    const nurses = [...this.props.sharedData.nurses];
    const responsibleNurseIndex = nurses.findIndex((nurse) => nurse.guid === currentMember.responsibleNurse?.guid);

    // Show the current PAS at the top of the list
    if (responsibleNurseIndex > -1) {
      nurses.splice(responsibleNurseIndex, 1);
      nurses.unshift(currentMember.responsibleNurse);
    }

    return (
      <ChatProvider>
        <Prompt
          message={(location) => {
            if ((messageSubject.length || messageBody.length) && !location.pathname.endsWith(currentMember.guid)) {
              return I18n.t('member_details.unsaved_changes_prompt');
            }

            return true;
          }}
        />
        <div className="details-container">
          {!memberError ? (
            <Fragment>
              <div className="columns is-marginless">
                <div className="column is-4">
                  <ProfileCard
                    member={currentMember}
                    memberActions={[
                      {
                        i18nKey: 'member_details.actions.create_shipment',
                        authority: authority.managePatients,
                        onClick: this.createShipment('outgoing')
                      },
                      {
                        i18nKey: 'member_details.actions.create_replacement_shipment',
                        authority: authority.managePatients,
                        onClick: this.createShipment('outgoing', 'replacement')
                      },
                      {
                        i18nKey: 'member_details.actions.create_return',
                        authority: authority.managePatients,
                        onClick: this.createShipment('return')
                      },
                      {
                        i18nKey: 'member_details.actions.create_kalmar_return',
                        authority: authority.managePatients,
                        onClick: this.createShipment('return', 'manualKalmar')
                      },
                      {
                        i18nKey: 'member_details.actions.create_invoice',
                        authority: authority.managePatients,
                        onClick: this.sendMonitorInvoice,
                        disabled: !shipmentStatus
                      },
                      {
                        i18nKey: 'member_details.actions.export_journal',
                        authority: authority.exportJournal,
                        onClick: this.props.toggleExportJournalModal
                      },
                      {
                        i18nKey: 'member_details.actions.show_spar_info',
                        authority: authority.managePatients,
                        onClick: this.showSparData
                      },
                      {
                        i18nKey: 'member_details.actions.open_in_clinic',
                        authority: authority.loginClinic,
                        type: 'link',
                        url: `https://${
                          process.env.REACT_APP_API_ENV !== 'production'
                            ? getEnvironmentPrefix(process.env.REACT_APP_API_ENV || CURRENT_ENV) + '.'
                            : ''
                        }clinic.accumbo.se/app/${currentMember.enableNextGeneration ? 'medicalrecord' : 'member'}/${
                          currentMember.guid
                        }`
                      }
                    ]}
                    actionsAuthority={[authority.managePatients, authority.exportJournal]}
                    userAuthorities={this.props.authorities}
                    error={memberError}
                    isLoading={fetchingMember}
                    onSendMessage={this.props.showMessageModal}
                    userType="member"
                  />
                  <Generation legacy>
                    <CanPerform action={authority.managePatients}>
                      <div className="card-container">
                        <DeactivationFunnel memberId={this.props.match.params.memberId} />
                        {currentMember.serviceStatus &&
                        currentMember.serviceStatus !== MEMBER_SERVICE_STATUS.HIDDEN &&
                        currentMember.serviceStatus !== MEMBER_SERVICE_STATUS.BLOCKED ? (
                          <div className="columns is-mobile">
                            <div className="column">
                              <Button onClick={this.hideMember} fullWidth={true} buttonType="secondary">
                                <Translate value="member_details.mark_inactive" />
                              </Button>
                            </div>
                            <div className="column flex-none vertical-align">
                              <Tooltip
                                title={
                                  <div className="fs-12">
                                    <Translate value="member_details.inactive_tooltip" />
                                  </div>
                                }
                                arrow
                                placement="top"
                              >
                                <span className="info-circle">?</span>
                              </Tooltip>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </CanPerform>
                  </Generation>
                  <Generation next>
                    <PatientDataErasure />
                  </Generation>
                  <CanPerform action={authority.managePatients}>
                    <div
                      className="card-container comment-container"
                      style={currentCommentColor ? { backgroundColor: currentCommentColor } : null}
                    >
                      <div className="columns is-mobile">
                        <div className={`column is-4 comment-header ${currentCommentColor ? 'has-color' : ''}`}>
                          <Translate value="member_details.comment" />
                        </div>
                        <div className="column text-right">
                          <div className="color reset" data-color={null} onClick={this.changeCommentColor}></div>
                          {COMMENT_COLORS.map((color) => (
                            <div
                              key={color}
                              className="color"
                              style={{ backgroundColor: color }}
                              data-color={color}
                              onClick={this.changeCommentColor}
                            ></div>
                          ))}
                        </div>
                      </div>
                      <DebounceInput
                        element="textarea"
                        maxLength="160"
                        value={memberCommentText}
                        debounceTimeout={200}
                        onChange={this.props.updateMemberCommentText}
                      />
                      {memberCommentText !== memberComment.text && !this.props.members.loadingMemberComment ? (
                        <div className="mt-20">
                          <LoaderButton
                            isLoading={this.props.members.updatingMemberComment}
                            fullWidth={true}
                            onClick={this.saveNewComment}
                          >
                            <Translate value="global.buttons.save" />
                          </LoaderButton>
                        </div>
                      ) : null}
                    </div>
                  </CanPerform>
                  <CanPerform action={authority.managePatients}>
                    <div className="card-container">
                      <div className="jira-service-desk-logo"></div>
                      <div className="mb-25">
                        {jira.loadingJiraIssues ? (
                          <LoadingIcon type="spinner-secondary" size="small" />
                        ) : jira.jiraIssues.length === 0 ? (
                          <Translate className="jira__no-content" value="member_details.no_issues" />
                        ) : (
                          <span className="text-button fw-normal" onClick={this.props.showJiraHistoryModal}>
                            <Translate
                              value={`member_details.issue_${jira.jiraIssues.length === 1 ? 'single' : 'multiple'}`}
                              numIssues={jira.jiraIssues.length}
                            />
                          </span>
                        )}
                      </div>
                      <div className="create-issue-container">
                        <Button onClick={this.props.showJiraModal} fullWidth={true}>
                          <span className="icon jira"></span>
                          <Translate value="member_details.create_issue" />
                        </Button>
                      </div>
                    </div>
                  </CanPerform>
                </div>
                <div className="column">
                  <ContentLoader
                    isLoading={fetchingMember}
                    error={memberError}
                    errorTitleI18n="member_details.error_fetching"
                  >
                    <Switch>
                      <PrivateRoute
                        exact
                        path={`/${APPLICATION_BASE_PATH}/member/:memberId`}
                        component={MemberDetailsInformation}
                      />
                    </Switch>
                  </ContentLoader>
                </div>
              </div>
            </Fragment>
          ) : (
            <ErrorFallback>
              <Translate value="member_details.error_fetching" />
            </ErrorFallback>
          )}
        </div>
        <Modal
          headerI18nKey="jira.modal_header"
          actionI18nKey="jira.modal_button"
          visible={jira.modalVisible}
          onClose={this.props.hideJiraModal}
          onActionCompleted={this.createJiraIssue}
          actionCompletable={!!jira.selectedSupportType}
          actionCompleting={jira.creatingIssue}
          contentClass="min-height-400"
        >
          <JiraSupportModalContent
            content={jira}
            onSelectIssueType={this.props.setJiraIssueType}
            onChangeDescription={this.props.setJiraIssueDescription}
          />
        </Modal>
        <Modal
          headerI18nKey="jira.history_header"
          actionI18nKey="global.buttons.ok"
          visible={jira.historyModalVisible}
          onClose={this.props.hideJiraHistoryModal}
          onActionCompleted={this.props.hideJiraHistoryModal}
          actionCompletable={true}
        >
          {jira.jiraIssues.length ? (
            <Fragment>
              <div className="columns fs-14 mb-5 is-mobile">
                <div className="column is-6 no-padding">
                  <Translate value="jira.history_column.issue" />
                </div>
                <div className="column is-4 no-padding">
                  <Translate value="jira.history_column.created" />
                </div>
                <div className="column is-2 no-padding">
                  <Translate value="jira.history_column.status" />
                </div>
              </div>
              {jira.jiraIssues.map((issue) => {
                return (
                  <div className="columns member-jira-issue is-mobile" key={issue.key}>
                    <div className="column is-6 no-padding">
                      <a
                        href={`https://jira.accumbo.com/browse/${issue.key}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {issue.summary}
                      </a>
                    </div>
                    <div className="column is-4 no-padding">
                      {moment(issue.createdDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
                    </div>
                    <div className="column is-2 no-padding">
                      <Translate value={issue.status} />
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ) : (
            <Translate value="jira.history_empty" />
          )}
        </Modal>
        <Modal
          header={I18n.t('member_details.send_message_to', {
            name: `${currentMember.givenName} ${currentMember.familyName}`
          })}
          actionI18nKey="global.buttons.send_message"
          visible={this.props.members.messageModalActive}
          onClose={this.closeMessageModal}
          onActionCompleted={this.sendMessage}
          actionCompletable={messageBody.trim().length > 0 && messageSubject.trim().length > 0}
          actionCompleting={this.props.members.sendingMessage}
          size="medium"
        >
          <Suspense fallback={<SkeletonComponent height="200px" width="100%" />}>
            <MemberMessageEditor
              locale={this.props.locale}
              messageSubject={messageSubject}
              messageBody={messageBody}
              onSubjectChanged={this.props.updateMessageSubject}
              onBodyChanged={this.props.updateMessageBody}
            />
          </Suspense>
        </Modal>
        <Modal
          headerI18nKey="member_details.actions.export_journal"
          actionI18nKey="member_details.actions.export_journal"
          visible={this.props.members.exportJournalModalVisible}
          onClose={this.props.toggleExportJournalModal}
          onActionCompleted={this.downloadMemberJournal}
          actionCompletable={this.state.selectedJournalInterval !== null}
          actionCompleting={this.props.members.fetchingMemberJournal}
          size="auto"
          contentClass="min-height-210"
        >
          <h4 className="mt-0">
            <Translate value="member_details.timespan_header" />
          </h4>
          <EditableSelect
            isEditable={true}
            isEditing={true}
            requiredAuthority="manageUsers"
            options={[
              { value: '14', label: I18n.t('member_details.journal_timespan.two_weeks') },
              { value: '30', label: I18n.t('member_details.journal_timespan.one_month') },
              { value: '90', label: I18n.t('member_details.journal_timespan.ninety_days') }
            ]}
            errorI18nKey="member_details.error_fetching_pal_list"
            onChange={(e) => {
              this.setState({ selectedJournalInterval: e.value });
            }}
            placeholder={I18n.t('member_details.choose_journal_timespan')}
            name="export-journal-select"
          />
        </Modal>
        <Modal
          headerI18nKey="member_details.spar.modal_header"
          actionI18nKey="global.buttons.ok"
          visible={this.props.members.sparModalVisible}
          onClose={this.props.toggleSparModal}
          onActionCompleted={this.props.toggleSparModal}
          actionCompletable={true}
          size="medium"
          additionalFooterContent={
            <Fragment>
              <div className="columns mb-5">
                <div className="no-padding fs-14">
                  <strong>Tilltalsnamn</strong>: Kod som anger vilka av förnamnen som är tilltalsnamn. Om det inte finns
                  någon kod för tilltalsnamn finns inget tilltalsnamn angivet i folkbokföringen och därmed inte i SPAR.
                </div>
              </div>
              <div className="columns mb-10">
                <div className="no-padding fs-14">
                  <strong>Exempel</strong>: 20 = 2 anger att det andra förnamnet är tilltalsnamnet. 12 = 12 anger att
                  det första och andra förnamnet är tilltalsnamn. (förnamn med bindestreck betraktas som två namn)
                </div>
              </div>
            </Fragment>
          }
        >
          <ContentLoader
            isLoading={this.props.members.fetchingSparData}
            error={this.props.members.sparError}
            errorTitleI18n="member_details.spar.error"
            type="spinner-secondary"
          >
            {this.convertSparToReadable(this.props.members.sparData)}
          </ContentLoader>
        </Modal>
        <Generation next>
          <Chat patient={currentMember} />
        </Generation>
      </ChatProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes,
    members: state.members,
    jira: state.jira,
    sharedData: state.sharedData,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  getMember,
  getMemberDevices,
  toggleEditMemberDetails,
  getAssignableDoctors,
  setMemberServiceStatus,
  showNotification,
  getMemberComment,
  updateMemberComment,
  updateMemberCommentText,
  getMemberProfileImage,
  showJiraModal,
  hideJiraModal,
  getMemberJiraIssues,
  getJiraIssueTypes,
  postMemberJiraIssue,
  setJiraIssueType,
  setJiraIssueDescription,
  hideJiraHistoryModal,
  showJiraHistoryModal,
  addManualShipment,
  getScheduledCommunication,
  showMessageModal,
  hideMessageModal,
  sendMemberMessage,
  updateMessageSubject,
  updateMessageBody,
  toggleExportJournalModal,
  getMemberJournalRequest,
  getMemberJournalSuccess,
  getMemberJournalError,
  getMemberChatMessages,
  toggleSparModal,
  getMemberSparData,
  getMemberPaymentHistory,
  getMemberShipmentStatus,
  getMemberState,
  sendMonitorInvoice,
  getMemberMonitors,
  getMonitorSizes,
  updateMemberState,
  getFunnelStatus
};

export default connect(mapStateToProps, mapActionsToProps)(MemberDetailsView);

export const sv = {
  global: {
    title: 'Accumbo Admin',
    loading: 'Hämtar...',
    dark_mode: 'Mörkt läge',
    change_language: 'Byt språk',
    buttons: {
      save: 'Spara',
      done: 'Klar',
      cancel: 'Avbryt',
      ok: 'OK',
      generate: 'Generera',
      block: 'Blockera användare',
      reactivate: 'Återaktivera användare',
      send_message: 'Skicka meddelande',
      remove: 'Ta bort',
      edit: 'Redigera',
      add: 'Lägg till',
      retry: 'Försök igen',
      send: 'Skicka'
    },
    male: 'Man',
    female: 'Kvinna',
    authorities: 'Rättigheter',
    personal_number: 'Personnummer',
    name: 'Namn',
    created: 'Skapad',
    search: 'Sök',
    previous: 'Föregående',
    next: 'Nästa',
    device: {
      ANDROID: 'Android',
      IOS: 'iOS',
      UNKNOWN: 'Okänd'
    },
    years: 'år',
    show_error_info: 'Visa detaljerad information',
    reason: 'Orsak',
    logout: 'Logga ut',
    login: 'Logga in',
    status: 'Status',
    service_status: {
      active: 'Aktiv',
      hidden: 'Inaktiv',
      blocked: 'Blockerad',
      nurse: 'Hanteras av SSK',
      doctor: 'Hanteras av läkare',
      deactivating: 'Avaktiveras'
    },
    choose: 'Välj...',
    all: 'Alla',
    choose_date: 'Välj datum',
    choose_caregiver: 'Välj vårdgivare',
    choose_role: 'Välj roll',
    choose_period: 'Välj period',
    period: 'Period',
    username: 'Användarnamn',
    password: 'Lösenord',
    disallowed_route: 'Du har inte åtkomst till denna sida. 😐',
    from_date: 'Från',
    to_date: 'Till',
    from_date_incl: 'Fr.o.m.',
    to_date_incl: 'T.o.m.',
    administrator: 'Administratör',
    user_title: 'Roll',
    no_address: 'Adress saknas',
    copy: 'Kopiera',
    yes: 'Ja',
    no: 'Nej',
    doctor: 'Läkare',
    nurse: 'Sjuksköterska',
    role: 'Roll',
    caregiver: 'Vårdgivare',
    patients: 'patienter',
    of: 'av',
    both: 'Båda',
    back: 'Tillbaka',
    previous_caregiver: {
      temporary: 'Tillfällig',
      permanent: 'Ordinarie'
    },
    final_note_reason: {
      checkup: 'Ospecificerad kontroll',
      new_member_checkup: 'Första läkarkontrollen',
      three_month_checkup: '3-månaderskontroll',
      yearly_checkup: 'Årskontroll',
      extra_checkup: 'Extra kontroll',
      six_week_checkup: '6-veckorskontroll',
      initial_checkup: 'Initiering av hemblodtrycksmätning',
      diagnosis_screening: 'Screening',
      examination_follow_up: 'Uppföljning fysisk undersökning',
      nurse_treatment: 'Sjukvårdande behandling, hypertoni',
      nurse_new_member: 'SSK ny patient',
      nurse_two_week: 'SSK tvåveckorskontroll',
      nurse_six_month: 'SSK sexmånaderskontroll',
      nurse_yearly: 'SSK årskontroll',
      nurse_extra: 'SSK extrakontroll',
      missing: 'Kontrolltyp saknas'
    },
    final_note_statistics_patient_group: {
      old_patients: 'Patienter före 2022-01-01',
      new_patients: 'Patienter efter 2022-01-01',
      total: 'Total'
    },
    payment_state: {
      created: 'Betalning begärd',
      pending: 'Påbörjad',
      exemption: 'Frikort',
      complete_unknown: 'Betald',
      complete_swish: 'Betald med Swish',
      complete_card: 'Betald med kort',
      complete_invoice: 'Betald med faktura',
      failed: 'Betalning misslyckades',
      error: 'Betalning misslyckades',
      cancelled: 'Avbruten'
    },
    payment_provider: {
      nordeaconnect: 'Nordea',
      invoice: 'Faktura',
      billogram: 'Billogram'
    },
    show_all: 'Visa alla',
    show_fewer: 'Visa färre',
    monitor_size: {
      S: 'Small (18-21 cm)',
      M: 'Medium (22-32 cm)',
      L: 'Large (33-42 cm)',
      XL: 'XLarge (43-56 cm)'
    },
    optional: 'Frivilligt',
    year: 'År',
    quarter: 'Kvartal',
    month: 'Månad',
    monitor: {
      ihealth: 'iHealth',
      truly: 'Truly',
      otherManual: 'Manuell/egen mätare'
    },
    bp_monitor: 'Blodtrycksmätare',
    none: 'Inga',
    undiagnosed: 'Odiagnostiserad patient',
    other: 'Övrigt',
    open: 'Öppna'
  },
  icon: {
    service_status: {
      blocked: 'Blockerad'
    },
    last_measure_date: 'Senaste mätning',
    last_lab_result_date: 'Senaste labbsvar',
    closed_support_issues: 'Stängda supportärenden: %{value}',
    open_support_issues: 'Öppna supportärenden: %{value}',
    last_monitor_sent_date: 'Mätare skickad'
  },
  notification: {
    save_generic: {
      success: 'Nytt värde sparat.',
      error: 'Det nya värdet kunde inte sparas.'
    },
    service_status_active: {
      success: '%{name} har avblockerats',
      error: '%{name} kunde inte avblockeras'
    },
    service_status_blocked: {
      success: '%{name} har blockerats',
      error: '%{name} kunde inte blockeras'
    },
    service_status_hidden: {
      success: '%{name} har markerats som inaktiv',
      error: '%{name} kunde inte markeras som inaktiv'
    },
    save_arm_circumference: {
      success: 'Ny mätarstorlek sparad.',
      error: 'Det gick inte att spara mätarstorleken.'
    },
    send_bulk_message: {
      success: 'Meddelande skickat.',
      error: 'Meddelandet kunde inte skickas.'
    },
    update_authorities: {
      error: 'Det gick inte att uppdatera rättigheterna.'
    },
    update_hidden_regions: {
      error: 'Det gick inte att uppdatera otillåtna regioner.'
    },
    update_hidden_sources: {
      error: 'Det gick inte att uppdatera otillåtna källor.'
    },
    new_handover: {
      success: 'Överlämning registrerad.',
      error: 'Det gick inte att registrera överlämningen.'
    },
    delete_handover: {
      success: 'Överlämning avbruten.',
      error: 'Det gick inte att avbryta överlämningen.'
    },
    update_handover: {
      success: 'Slutdatum för överlämningen ändrat.',
      error: 'Det gick inte att ändra slutdatum.'
    },
    send_bulk_referrals: {
      success: 'Utskickning av remisser påbörjad.',
      error: 'Det gick inte att skicka remisser.'
    },
    save_comment: {
      success: 'Anteckning sparad.',
      error: 'Det gick inte att spara anteckningen.'
    },
    create_jira_issue: {
      success: 'Ärende skapat',
      error: 'Kunde inte skapa ärendet.'
    },
    delete_shipment: {
      error: 'Kunde inte ta bort utskicket.'
    },
    add_manual_shipment_outgoing: {
      success: 'Försändelse skapad.',
      error: 'Kunde inte skapa försändelse.'
    },
    add_manual_shipment_return: {
      success: 'Retur skapad.',
      error: 'Kunde inte skapa retur.'
    },
    delay_shipment: {
      success: 'Försändelsen har skjutits upp.',
      error: 'Kunde inte skjuta upp försändelsen.'
    },
    undo_delay_shipment: {
      success: 'Uppskjutningen ångrades.',
      error: 'Uppskjutningen kunde inte ångras.'
    },
    update_shipment_delay: {
      success: 'Uppskjutningsdatumet uppdaterades.',
      error: 'Kunde inte uppdatera uppskjutningsdatumet.'
    },
    update_communication: {
      error: 'Kunde inte skjuta upp kommunikationen.'
    },
    delete_communication: {
      error: 'Kunde inte ta bort kommunikationen.'
    },
    update_canned_response_title: {
      success: 'Rubrik uppdaterad till "%{title}"',
      error: 'Kunde inte uppdatera rubriken.'
    },
    update_canned_response_body: {
      success: 'Text för "%{title}" uppdaterad.',
      error: 'Kunde inte uppdatera texten för "%{title}"'
    },
    create_canned_response: {
      error: 'Kunde inte skapa kategorin/rubriken.'
    },
    delete_canned_response: {
      error: 'Kunde inte ta bort kategorin/rubriken.'
    },
    update_canned_response: {
      error: 'Kunde inte uppdatera kategorin/rubriken.'
    },
    member_message: {
      success: 'Meddelande skickat.',
      error: 'Meddelandet kunde inte skickas. Försök igen eller kontakta teknisk support.'
    },
    export_journal: {
      error: 'Kunde inte ladda ner journal. Försök igen eller kontakta teknisk support.'
    },
    priority_saved: {
      success: 'Förtur sparad för %{name}.',
      error: 'Det gick inte att spara förtursinställningen.'
    },
    priority_removed: {
      success: 'Förtur borttagen för %{name}',
      error: 'Det gick inte att ta bort förturen.'
    },
    payment_exemption_added: {
      success: 'Frikort sparat.',
      error: 'Det gick inte att spara frikortet.'
    },
    payment_exemption_updated: {
      success: 'Frikort uppdaterat.',
      error: 'Det gick inte att uppdatera frikortet.'
    },
    payment_exemption_removed: {
      success: 'Frikort borttaget.',
      error: 'Det gick inte att ta bort frikortet.'
    },
    download_pdf: {
      error: 'Kunde inte ladda ner PDF. Försök igen eller kontakta teknisk support.'
    },
    download_receipt: {
      error: 'Kunde inte ladda ner kvittot. Försök igen eller kontakta teknisk support.'
    },
    download_invoice: {
      error: 'Kunde inte ladda ner faktura. Försök igen eller kontakta teknisk support.'
    },
    upload_profile_picture: {
      success: '%{name}s profilbild uppdaterades.',
      error: 'Det gick inte att ladda upp bilden.'
    },
    tag_incoming_patients: {
      success_single: 'Personnumret taggades med "%{source}".',
      success_multiple: '%{numPatients} personnummer taggades med "%{source}".',
      error: 'Det gick inte att tagga. Försök igen eller kontakta teknisk support.'
    },
    get_final_note_breakdown: {
      error: 'Kunde inte hämta slutanteckningsstatistiken.'
    },
    get_final_notes_per_patient_group: {
      error: 'Kunde inte hämta slutanteckningsstatistiken.'
    },
    create_user: {
      success: 'Ny %{type} skapad.',
      error: 'Det gick inte att skapa användaren. Försök igen eller kontakta teknisk support.'
    },
    update_member_state: {
      success: '%{stateName} %{stateAction}',
      error: 'Kunde inte uppdatera tillstånd för: %{stateName}'
    },
    send_monitor_invoice: {
      success: 'Faktura för mätaren skickad.',
      error: 'Det gick inte att skicka fakturan.',
      error_conflict:
        'Denna patient har redan fått en faktura för sin blodtrycksmätare. En ny faktura har därför inte skapats.'
    },
    deactivate_member: {
      success: '%{name} är nu avaktiverad.',
      error: 'Det gick inte att avaktivera %{name}.'
    },
    reactivate_member: {
      success: '%{name} är nu återaktiverad.',
      error: 'Det gick inte att återaktivera %{name}.'
    },
    create_delivery_batch: {
      error: 'Det gick inte att spara batchen. Försök igen eller kontakta support.'
    },
    download_transactions: {
      error: 'Det gick inte att ladda ner verifikatet. Försök igen eller kontakta support.'
    },
    add_partner_user: {
      success: 'Ny användare skapad.',
      error: 'Kunde inte skapa användaren. Försök igen eller kontakta support.'
    },
    update_partner_user: {
      success: 'Användaren uppdaterades.',
      error: 'Kunde inte uppdatera användaren. Försök igen eller kontakta support.'
    },
    remove_partner_user: {
      success: 'Användaren togs bort.',
      error: 'Kunde inte ta bort användaren. Försök igen eller kontakta support.'
    },
    instructions_file_download: {
      error: 'Kunde inte hämta instruktionsfilen.'
    },
    partner_user_export: {
      error: 'Kunde inte hämta exporten.'
    },
    create_partner: {
      error: 'Kunde inte skapa partnern. Försök igen eller kontakta support.'
    },
    update_partner: {
      error: 'Kunde inte uppdatera partnern. Försök igen eller kontakta support.'
    },
    remove_partner: {
      success: 'Partnerorganisationen togs bort.',
      error: 'Kunde inte ta bort partnerorganisationen. Försök igen eller kontakta support.'
    },
    create_facility: {
      error: 'Kunde inte skapa mottagningen. Försök igen eller kontakta support.'
    },
    update_facility: {
      error: 'Kunde inte uppdatera mottagningen. Försök igen eller kontakta support.'
    },
    remove_facility: {
      error: 'Kunde inte ta bort mottagningen. Försök igen eller kontakta support.'
    },
    send_kiosk_activation_code: {
      success: 'Inställningskoden skickades till %{email}.',
      error: 'Kunde inte skicka inställningskoden. Försök igen eller kontakta support.'
    },
    update_program: {
      error: 'Det gick inte att ändra programmet.'
    },
    url_copied: {
      success: 'URL:en kopierades till urklipp.'
    },
    save_absence: {
      success: 'Frånvaron sparades.',
      error: 'Det gick inte att spara frånvaron.'
    },
    remove_absence: {
      success: 'Frånvaron togs bort.',
      error: 'Det gick inte att ta bort frånvaron.'
    },
    save_allocation: {
      error: 'Det gick inte att spara beläggningen.'
    },
    update_caregiver: {
      error: 'Kunde inte spara ny vårdgivare.',
      error_conflict: 'Kunde inte spara. Vald vårdgivare har en planerad frånvaro i närtid.'
    }
  },
  menu: {
    user_search: 'Patienter',
    support: 'Supportcenter',
    system_users: 'Systemanvändare',
    reports: 'Rapporter',
    services: 'Tjänster',
    planning: 'Planering'
  },
  login_view: {
    start_login: 'Logga in med Mobilt BankID',
    footer_info: ' • Bredbandet 1, 392 30 Kalmar • 0480-797 960 • support@blodtrycksdoktorn.se',
    generic_error: 'Inloggningen misslyckades',
    instruction1:
      'Starta BankID-appen i din mobil och tryck på <strong>Skanna QR-kod</strong>. Rikta kameran mot QR-koden här ovanför.',
    instruction2: 'Skriv in din säkerhetskod i BankID-appen och välj <strong>Identifiera</strong>.',
    cancel_login: 'Avbryt inloggning'
  },
  member_search: {
    input_placeholder: 'Sök på namn, personnummer, telefon eller e-postadress',
    no_results: 'Vi hittade ingen person som matchade dina sökkriterier.',
    showing_x_of_y_results: 'Visar %{currentPage} av %{totalCount} resultat',
    error: 'Kunde inte hämta användare.',
    show_advanced_search: 'Visa avancerade sökalternativ',
    hide_advanced_search: 'Dölj avancerade sökalternativ',
    special_searches: 'Specialsökningar',
    all_regions: 'Alla regioner',
    all_sources: 'Alla källor',
    all_platforms: 'Alla plattformar',
    all_arm_circumferences: 'Alla storlekar',
    created_date: 'Registreringsdatum',
    is_next_gen: 'Appgeneration'
  },
  system_user_search: {
    assignment_priority: {
      give_priority_header: 'Ge förtur till paxning av patienter',
      current_patients: 'Nuvarande antal patienter',
      patients_increase: 'Antal patienter att öka med',
      patients_increase_placeholder: 'Exempelvis 200',
      priority_overview_header: 'Översikt av förtur till paxning ',
      priority_start_date: 'Startdatum',
      patients_at_start: 'Vid start',
      priority_limit: 'Fick förtur till',
      assigned: 'Paxat',
      delete_button: 'Ta bort förtur',
      give_priority: 'Ge förtur',
      has_priority: 'Har förtur'
    },
    create_user: '+ Ny användare'
  },
  member_details: {
    error_fetching: 'Kunde inte hämta användarinformation. Prova igen senare eller kontakta teknisk support.',
    error_fetching_region_list: 'Kunde inte hämta regionlista',
    change_details: 'Ändra uppgifter',
    given_name: 'Förnamn',
    family_name: 'Efternamn',
    phone_number: 'Telefon',
    email: 'E-post',
    age: 'Ålder',
    street_address: 'Gatuadress',
    postal_code: 'Postnummer',
    city: 'Stad',
    platform: 'Plattform',
    created: 'Skapad',
    region: 'Provtagningsregion',
    source: 'Källa',
    update_error: 'Kunde inte spara uppdaterade användaruppgifter. Vänligen försök igen.',
    unsaved_changes_prompt: 'Det finns osparade ändringar. Lämna sidan ändå?',
    responsible_doctor: 'Patientansvarig läkare',
    responsible_nurse: 'Patientansvarig sjuksköterska',
    not_active_yet: 'Ej aktiv ännu',
    error_fetching_pal_list: 'Kunde inte hämta läkarlista',
    error_fetching_pas_list: 'Kunde inte hämta sjuksköterskelista',
    confirm_member_block: 'Är du säker på att du vill blockera den här användaren?',
    block_member_error: 'Kunde inte blockera användaren.',
    reactivate_member_error: 'Kunde inte återaktivera användaren',
    treatment: 'Behandling',
    latest_lab_results: 'Senaste labbsvar',
    testing: 'Provtagning',
    arm_circumference: 'Mätarstorlek',
    select_monitor_size: 'Välj storlek',
    general: 'Allmänt',
    blocked: 'Blockerad/avaktiverad',
    block_member: 'Avaktivera patient',
    unblock_member: 'Återaktivera patient',
    block_without_return_slip: 'Avaktivera utan retur',
    block_with_return_slip: 'Avaktivera med retur',
    block_without_return_slip_details: 'Patienten behöver inte lämna tillbaka sin mätare.',
    block_with_return_slip_details: 'Patienten behöver lämna tillbaka sin mätare.',
    blocked_without_return_slip: 'Patient avaktiverad utan retur.',
    blocked_with_return_slip: 'Patient avaktiverad med retur.',
    blocked_label: 'Patienten är avaktiverad',
    ongoing_deactivation: 'Pågående avaktivering',
    pause_deactivation: 'Pausa avaktivering',
    paused_deactivation: 'Pausad avaktivering',
    resume_deactivation: 'Återuppta avaktivering',
    address_information: 'Adressuppgifter',
    no_address_information: 'Inga adressuppgifter',
    contact_information: 'Kontaktuppgifter',
    no_contact_information: 'Inga kontaktuppgifter',
    edit_profile: 'Redigera profil',
    update_profile_error: 'Det gick inte att uppdatera profilen just nu.',
    save_details: 'Spara uppgifter',
    biometrics: 'Biometri',
    insurance_header: 'Försäkringsinformation',
    claim_id: 'SkadeID',
    create_issue: 'Skapa nytt ärende',
    no_issues: 'Inga ärenden',
    issue_single: '1 ärende',
    issue_multiple: '%{numIssues} ärenden',
    choose_region: 'Välj en region',
    comment: 'Anteckning',
    last_measurement: 'Senaste mätning',
    last_login: 'Senaste inloggning',
    send_message_to: 'Skicka meddelande till %{name}',
    send_message: 'Skicka meddelande',
    message_subject_header: 'Ämnesrad',
    message_body_header: 'Meddelande',
    message_discard_alert: 'Kasta meddelandet du börjat skriva?',
    monitor_sent: 'Mätare skickad',
    monitor_shipment_status: 'Mätarutskick',
    shipment_status_updated: 'Status uppdaterad %{timestamp}',
    mark_inactive: 'Markera som inaktiv',
    inactive_tooltip:
      'Patienten kommer inte dyka upp i läkarens ärendelista förrän patienten har loggat in i tjänsten på nytt.',
    chat_communication_header: 'Läst senaste meddelande',
    empty_chat: 'Inga chattmeddelanden',
    choose_journal_timespan: 'Välj tidsperiod...',
    timespan_header: 'Tidsperiod',
    journal_timespan: {
      two_weeks: '2 veckor',
      one_month: '1 månad',
      ninety_days: '90 dagar'
    },
    deregister: 'Avregistrera patient',
    deregister_confirmation: 'Är du säker på att du vill avregistrera patienten?',
    deregistered: 'Markerad för avregistrering %{date}',
    updates: {
      responsible_doctor: {
        success: 'PAL ändrad till %{newValue}.',
        error: 'Kunde inte ändra PAL.'
      },
      responsible_nurse: {
        success: 'PAS ändrad till %{newValue}.',
        error: 'Kunde inte ändra PAS.'
      },
      region: {
        success: 'Region ändrad till %{newValue}.',
        error: 'Kunde inte ändra region.'
      }
    },
    actions: {
      create_shipment: 'Skicka mätare',
      create_replacement_shipment: 'Skicka ersättningsmätare',
      create_return: 'Skapa retur till Mönsterås',
      create_kalmar_return: 'Skapa retur till Kalmar',
      export_journal: 'Skapa journalutdrag',
      show_spar_info: 'Visa information från SPAR',
      open_in_clinic: 'Öppna i Clinic',
      create_invoice: 'Skicka faktura för mätaren'
    },
    scheduled_communication: {
      header: 'Schemalagd kommunikation',
      empty: 'Ingen kommunikation har schemalagts för denna patient.',
      name: 'Ärende',
      sends_in: 'Skickas om',
      delete: 'Ta bort',
      confirm_delete: 'Är du säker på att du vill ta bort kommunikationen?',
      postpone: 'Förskjut 1 vecka',
      edit: 'Ändra',
      error: 'Kunde inte hämta schemalagd kommunikation.',
      type: {
        email: 'E-post',
        sms: 'SMS',
        push: 'Push',
        jira: 'Jira',
        chat: 'Chatt'
      }
    },
    funnel_state: {
      header: 'Status',
      inactive: 'Ej aktuell',
      bottom_funnel: 'Bottom funnel',
      deactivation_funnel: 'Deactivation funnel',
      table_headers: {
        event: 'Händelse',
        date: 'Datum'
      },
      empty: 'Ingen pågående avaktivering',
      ongoing: 'Automatisk avaktivering påbörjad för:',
      error: 'Fel inträffade vid hämtning av status',
      days_to_invoice: 'Dagar kvar till automatisk faktura:',
      pause: 'Pausa påminnelser inför 1:a läkarkontroll',
      resume: 'Återuppta påminnelse inför 1:a läkarkontroll'
    },
    spar: {
      modal_header: 'Information från SPAR',
      error: 'Kunde inte hämta data från SPAR.',
      deregistration_code: {
        A: 'Avliden',
        AV: 'Avliden',
        G: 'Gammalt nummer',
        O: 'Övrig orsak',
        UV: 'Utvandrad',
        GN: 'Gammalt personnummer',
        GS: 'Gammalt samordningsnummer',
        AS: 'Annullering av samordningsnummer',
        OB: 'Försvunnen',
        TA: 'Teknisk avregistrering',
        FI: 'Falsk identitet'
      }
    },
    payment_exemption_header: 'Frikort',
    payment_exemption_card_number: 'Frikortsnummer',
    payment_exemption_card_date: 'Giltigt till',
    payment_exemption_confirm_removal: 'Är du säker på att du vill ta bort patientens frikort?',
    payment_history: {
      header: 'Patientavgiftshistorik',
      table_headers: {
        visit_type: 'Kontroll',
        status: 'Betalningsstatus',
        date: 'Datum',
        receipt: 'Kvitto'
      },
      error: 'Kunde inte hämta patientavgiftshistorik.',
      no_history: 'Patienten har ingen betalningshistorik.',
      download_receipt: 'Ladda ner kvitto',
      download_invoice: 'Ladda ner faktura'
    },
    monitor_list: {
      header: 'Mätte senast med',
      heading: {
        monitor: 'Mätare',
        sent: 'Skickades',
        first_measurement: 'Första mätning',
        last_measurement: 'Senaste mätning'
      }
    },
    program_management: {
      header: 'Program',
      action_trigger: 'Välj åtgärd',
      confirm_abort: 'Är du säker på att du vill avbryta programmet?',
      error: 'Kunde inte hämta programinformation',
      empty: 'Patienten har inga program',
      edit: 'Ändra',
      action: {
        resume: 'Återuppta',
        abort: 'Avbryt',
        help: 'Hjälp',
        leave: 'Lämna',
        redo: 'Gör om',
        skip: 'Hoppa över'
      },
      program: {
        AlcoholProgram: 'Alkoholprogram',
        DietProgram: 'Kostprogram',
        DirectOnboardingProgram: 'Onboarding (direkt)',
        DummyAppointmentProgram: 'Starta kontroll (testprogram)',
        ExtraDoctorAppointmentProgram: 'Extrakontroll',
        ExtraNurseAppointmentProgram: 'Extrainsats SSK',
        HLCAProgram: 'HCLA-program',
        HypertensionDiagnosedProgram: 'Nybesök',
        HypertensionFollowUpProgram: 'Uppföljning högt blodtryck',
        HypertensionTreatmentInitializationProgram: 'Blodtryckskontroll',
        HypertensionTreatmentQualification: 'Kvalificering för behandling eller utredning',
        HypertensionUndiagnosedProgram: 'Utredning av högt blodtryck',
        KioskNotQualifiedProgram: 'Kioskresultat för icke kvalificerade',
        KioskOnboardingProgram: 'Onboarding (Kiosk)',
        LabProgram: 'Skicka remiss för provtagning',
        LifestyleProgram: 'Livsstilsutredning',
        LifestyleTreatmentProgram: 'Livsstilsbedömning',
        NewStepsTestProgram: 'NewStepsTestProgram',
        NotificationTestProgram: 'Testa notiser',
        OffboardingProgram: 'Offboarding',
        PartnerOnboardingProgram: 'Onboarding (Partner)',
        ReboardingProgram: 'Reboarding',
        RiskAssessmentProgram: 'Utred risk för hypertonidiagnos',
        SmokingProgram: 'Rökavvänjning',
        SnuffProgram: 'Snusavvänjning',
        StressProgram: 'Stressprogram',
        WeightProgram: 'Viktprogram'
      },
      program_state: {
        ineligible: 'Oberättigad',
        eligible: 'Berättigad',
        paused: 'Pausat',
        started: 'Startat',
        planned: 'Planerat',
        skipped: 'Skippat',
        redo: 'Gör om',
        ongoing: 'Pågående',
        revalidate: 'Revalidera',
        completed: 'Genomfört',
        aborted: 'Avbrutet'
      },
      reason_category: {
        disqualified: 'Diskvalificerad',
        inactive: 'Inaktiv',
        leave: 'Lämnat',
        abort: 'Avbrutet',
        help: 'Hjälp',
        resume: 'Återupptagen',
        skip: 'Skippat'
      },
      reason: {
        age_under_35: 'Under 35 år',
        heart_or_renal_failure: 'Hjärt- eller njursvikt',
        pregnant: 'Gravid',
        low_probability: 'Låg sannolikhet',
        preparations_not_done: 'Förberedelser ej genomförda',
        optional_preparations_not_done: 'Valfria förberedelser ej genomförda',
        other_caregiver: 'Annan vårdgivare',
        no_need: 'Inget behov',
        personal: 'Personligt',
        other: 'Övrigt',
        not_interested: 'Inte intresserad',
        not_started: 'Inte startad',
        testing: 'Test',
        no_selected_action: 'Ingen vald åtgärd',
        care_plan_aborted: 'Behandlingsplan avbruten',
        redo: 'Gör om',
        aborted_by_admin: 'Avbruten av support',
        more_time: 'Mer tid',
        skip_optional_steps: 'Skippat valfria steg',
        resumed_by_admin: 'Återupptagen av support',
        no_time: 'Inte tid',
        no_available_time: 'Ingen tillgänglig tid',
        too_long_distance: 'För långt avstånd',
        cannot_leave_home: 'Kan inte lämna hemmet',
        not_relevant: 'Inte relevant'
      }
    },
    chat: {
      error: 'Kunde inte hämta chattflödet.',
      thumbnail_error: 'Kunde inte hämta bild.',
      image_modal_header: 'Bild från %{name}'
    },
    caregiver_history: {
      doctor: {
        header: 'Tidigare läkare',
        error: 'Kunde inte hämta tidigare läkare.',
        empty: 'Inga tidigare läkare.'
      },
      nurse: {
        header: 'Tidigare sjuksköterskor',
        error: 'Kunde inte hämta tidigare sjuksköterskor.',
        empty: 'Inga tidigare sjuksköterskor.'
      },
      period: 'Från %{from} till %{to}',
      unknown_date: 'okänt datum'
    },
    treatment_areas: {
      header: 'Vårdspår',
      cancel_treatment: 'Avsluta vårdspår',
      resend_return_monitor_email: 'Skicka returmejl igen',
      area: {
        hypertension: 'Hypertoni',
        obesity: 'Obesitas'
      },
      state: {
        investigation: 'Utredning',
        treatment: 'Behandling',
        onboarding: 'Onboarding',
        titration: 'Titrering',
        aborting: 'Under avslut',
        aborted: 'Avslutat'
      },
      modal_header: 'Avsluta vårdspår: %{treatmentArea}',
      reason_header: 'Ange en anledning',
      choose_reason: 'Välj anledning',
      prepopulated: 'Snabbval',
      patient_returns_monitor: 'Patienten returnerar mätaren'
    }
  },
  system_user_details: {
    disallowed_regions: 'Otillåtna områden',
    disallowed_sources: 'Otillåtna källor',
    no_more_regions: 'Inga regioner återstår',
    no_more_sources: 'Inga källor återstår',
    no_more_authorities: 'Inga rättigheter återstår',
    title: 'Titel',
    caregiver_handover: {
      header: 'Permanent patientöverlämning',
      secondary_caregivers_header: 'Mottagare',
      add_caregiver: '+ Lägg till fler mottagare',
      patients_remaining_header: 'Patienter kvar att fördela',
      patients_remaining: '%{numPatients} patienter',
      patients_remaining_singular: '1 patient',
      choose_doctor: 'Välj en läkare',
      choose_nurse: 'Välj sjuksköterska',
      save: 'Registrera överlämning',
      change_to_date: 'Ändra slutdatum',
      save_to_date: 'Spara slutdatum',
      remove: 'Avbryt överlämningen',
      confirm_removal: 'Är du säker på att du vill avbryta överlämningen?',
      period_header: 'Period',
      caregiver_list_header: 'Vikarierande läkare',
      type: 'Typ av överlämning',
      permanent: 'Permanent',
      temporary: 'Vikariat',
      error: {
        region: 'Kan inte fördela alla patienter till den valda läkaren.',
        no_caregiver_selection: 'Vänligen välj en läkare.',
        invalid_number: 'Skriv in ett giltigt antal patienter.',
        fetching_doctors: 'Kunde inte hämta läkarlista.',
        fetching: 'Kunde inte hämta aktiva patientöverlämningar.'
      }
    },
    tab: {
      permissions: 'Rättigheter',
      patient_handover: 'Patientöverlämning',
      planned_absence: 'Planerad frånvaro',
      metrics: 'Mätpunkter',
      allocation: 'Kapacitet'
    }
  },
  create_user_view: {
    header: 'Skapa ny systemanvändare',
    authority_group: 'Typ av användare',
    create_user: 'Skapa användare'
  },
  planning_view: {
    page: {
      absence_management: 'Hantera frånvaro',
      allocation: 'Beläggning',
      metrics: 'Mätpunkter'
    }
  },
  reports_view: {
    select_page: 'Välj bland tjänster i menyn',
    page: {
      invoice_report: 'Faktureringsunderlag',
      region_skane_referrals: 'Remisser för Region Skåne',
      bulk_message: 'Patientmeddelande',
      bulk_referrals: 'Remissutskick',
      shipments: 'Hantera försändelser',
      canned_responses: 'Hantera chattmallar',
      doctor_statistics: 'Vårdgivarstatistik',
      tag_incoming_patients: 'Tagga inkommande patienter',
      accounting: 'Bokföring',
      partner_management: 'Hantera partner'
    },
    invoice_report: {
      header: 'Faktureringsunderlag',
      month_header: 'Månad',
      year_header: 'År',
      no_data: 'Det finns inget faktureringsunderlag för den valda månaden.',
      error: 'Det gick inte att ladda ner faktureringsunderlaget.'
    },
    referrals: {
      header: 'Generera remisser för Region Skåne-patienter',
      modal_header: 'Konfigurera utskriftsinställningar',
      save_settings: 'Spara inställningar',
      new_referral: 'ny remiss',
      new_referrals: 'nya remisser',
      no_new_referrals: 'Inga nya remisser',
      error: 'Kunde inte hämta remisser.',
      test_error: 'Kunde inte hämta testremiss.',
      main_button: 'Generera remisser',
      rutin: 'Rutin 1',
      special: 'Special 3',
      x_offset: 'X-offset',
      y_offset: 'Y-offset',
      test_referral: 'Generera testremiss',
      configure: 'Konfigurera',
      desktop_only_note: 'Remissgenereringen är inte tillgänglig via mobilen.',
      get_error: 'Kunde inte hämta remisser.',
      no_referrals: 'Inga remisser att hantera.'
    },
    bulk_message: {
      header: 'Patientmeddelande',
      description:
        'När du skriver ett meddelande, markerar en eller flera läkare och klickar på "Skicka meddelande" nedan kommer ett chattmeddelande att gå iväg till alla de patienter som har de markerade läkarna som PAL. Notera att det inte går att ändra i meddelandet efter att det har skickats, och att det kan ta en stund innan det har nått alla mottagare.',
      no_more_caregivers: 'Inga fler läkare',
      select_caregivers: 'Välj behandlande läkare',
      caregiver_header: 'Behandlande läkare',
      message_header: 'Meddelande till patienter',
      send_message: 'Skicka meddelande',
      message_placeholder: 'Skriv meddelande här',
      confirm: 'Är du säker på att du vill skicka detta meddelande?'
    },
    bulk_referrals: {
      header: 'Skicka remisser till chatten',
      description:
        'Ladda upp en CSV-fil med personnummer för de patienter som i chatten ska få remissinformation från sin läkare. Filen ska innehålla ett personnummer per rad. Baserat på vald region kommer systemet för varje patient automatiskt att avgöra vilken remissinformation som skickas.',
      send_referrals: 'Skicka %{numReferrals} remisser',
      message_header: 'Meddelande i patientens chattflöde',
      message_placeholder: 'Skriv meddelande här',
      confirm: 'Är du säker på att du vill skicka remisser till dessa patienter?',
      file_format_error: 'Felaktigt formaterad data i den valda filen.',
      select_file: 'Välj CSV-fil'
    },
    shipments: {
      header: 'Hantera försändelser',
      no_shipments: 'Inga försändelser att hantera.',
      no_delayed_shipments: 'Inga dolda försändelser att hantera.',
      error: 'Kunde inte hämta försändelser.',
      done_button: 'Skicka till Logistified',
      created_at: 'Skapad',
      reason: 'Orsak',
      reasons: {
        manual: 'Manuell',
        batch: 'Automatisk',
        service_cancelled: 'Tjänst avslutad',
        manual_kalmar: 'Kalmar manuell'
      },
      type: {
        outgoing: 'Utskick',
        return: 'Retur'
      },
      active_shipments: 'Försändelselista',
      delayed_shipments: 'Dolda försändelser',
      delay: 'Skjut upp',
      delay_header: 'Skjut upp utskick',
      update_delay: 'Uppdatera datum',
      update_delay_header: 'Ändra uppskjutningsdatum',
      current_delay: 'Uppskjuten till %{delayDate}',
      undo_delay: 'Ångra uppskjutning',
      change_delay_date: 'Ändra datum',
      address_missing: 'Adress saknas'
    },
    canned_responses: {
      header: 'Hantera chattmallar',
      invalid_tag: 'Texten innehåller en ogiltig tagg. Kontrollera taggarna och försök igen.',
      start_editing: 'Redigera',
      cancel_editing: 'Avbryt redigering',
      save: 'Spara ändringar',
      add_node: '+ Textmall',
      add_sub_category: '+ Underkategori',
      add_main_category: '+ Huvudkategori',
      edit_text: 'Ändra text',
      tags_header: 'Tillgängliga taggar',
      confirm_cancel: 'Det finns ändringar som inte sparats. Vill du kasta dessa?',
      expand_all: 'Expandera alla',
      collapse_all: 'Stäng alla',
      paste_notice:
        '<strong>OBS:</strong> Om du klistrar in text från Google Docs eller liknande, se till att klista in utan formatering (<kbd>Ctrl</kbd>/<kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>V</kbd>). Formaterad text stöds inte i chatten.'
    },
    doctor_statistics: {
      header: 'Läkarstatistik',
      final_notes_header: 'Slutanteckningar',
      final_notes_per_patient_group_header: 'Slutanteckningar per patientgrupp',
      choose_year: 'Välj år',
      choose_doctor: 'Välj läkare',
      doctor_table_header: 'Läkare',
      nurse_table_header: 'Sjuksköterska',
      patient_totals_header: 'Antal patienter',
      total: 'Totalt',
      active: 'Aktiva',
      hidden: 'Inaktiva',
      blocked: 'Blockerade',
      statistics_error: 'Kunde inte hämta slutanteckningsstatistik.',
      patient_totals_error: 'Kunde inte hämta patientantal.',
      final_note_type_breakdown_header: 'Debiteringsunderlag',
      quarter: 'Kvartal (%{monthRange})',
      final_notes_for_month: 'Slutanteckningar under %{period}',
      final_notes_for_quarter: 'Slutanteckningar under kvartal %{period}',
      show_details: 'Visa detaljer',
      download_pdf: 'Ladda ner PDF',
      final_note_remuneration: 'Ersättning per slutanteckning, inkl. moms',
      total_excl_vat: 'Totalt exkl. moms',
      total_incl_vat: 'Totalt inkl. moms',
      monthly_billing: 'Månad',
      quarterly_billing: 'Kvartal',
      billing_period: 'Debiteringsperiod',
      caregiver_type: 'Vårdgivartyp'
    },
    tag_incoming_patients: {
      header: 'Tagga inkommande patienter',
      description:
        'Skriv in personnumret för en patient som ska taggas med den valda källan vid registrering. Om du anger ett mobilnummer till patienten används detta för att skicka ett sms med en nedladdningslänk.',
      add_more: '+ Lägg till fler',
      campaign: 'Kampanj'
    }
  },
  jira: {
    select_issue_type: 'Välj ärendetyp',
    modal_header: 'Skapa supportärende',
    modal_button: 'Skapa ärende',
    history_header: 'Supportärenden för denna patient',
    history_error: 'Kunde inte hämta ärendehistorik.',
    history_empty: 'Inga tidigare ärenden.',
    history_column: {
      issue: 'Ärende',
      created: 'Skapat',
      status: 'Status'
    },
    description: 'Beskrivning'
  },
  profile_image_upload: {
    header: 'Uppdatera profilbild',
    header_new: 'Välj profilbild',
    action: 'Ladda upp bild',
    select_file: 'Välj fil',
    drag_here: 'eller dra en bild hit'
  },
  authorities: {
    admin: 'Administratör',
    healthscientist: 'Hälsovetare',
    caregiver: 'Läkare',
    nurse: 'Sjuksköterska',
    login_clinic: 'Clinic-login',
    login_admin: 'Admin-login',
    manage_patients: 'Hantera patienter',
    manage_users: 'Hantera systemanvändare',
    manage_content: 'Hantera innehåll',
    send_communications: 'Patientkommunikation',
    manage_billing: 'Hantera fakturering',
    read_invoice_report: 'Skapa Priveraunderlag',
    view_patients: 'Visa patienter',
    export_journal: 'Exportera journaldata',
    handle_auscultation: 'Auskultationsbedömning',
    accounting: 'Bokföring',
    manage_partners: 'Hantera partner',
    handle_certificate_patients: 'Hantera intygspatienter',
    handle_next_gen_patients: 'Hantera NextGen-patienter',
    medical_management: 'Medicinsk ledning',
    manage_absence: 'Hantera frånvaro'
  },
  authority_description: {
    healthscientist: 'Alla hälsovetare i systemet måste ha denna.',
    caregiver: 'Alla läkare i systemet måste ha denna.',
    nurse: 'Alla sjuksköterskor i systemet måste ha denna.',
    login_clinic: 'Krävs för att kunna logga in i Clinic.',
    login_admin: 'Krävs för att kunna logga in i Admin.',
    manage_patients: 'Krävs för att ändra patientdata (e-postadress, telefonnummer osv.).',
    manage_users:
      'Krävs för att kunna administrera systemanvändare, och ändra data för dessa (t.ex. rättigheter eller semesterperioder) samt för att ändra en patients PAL.',
    manage_content: 'Krävs för att kunna göra ändringar i chattmallarna.',
    send_communications:
      'Krävs för att göra massutskick från Admin (Patientmeddelande, Remissutskick), samt för att skicka e-post till enskilda patienter.',
    manage_billing:
      'Krävs för att se statistik över läkarnas slutanteckningar och tilldelade patienter. Kommer att kunna användas för att skapa fakturautdrag för läkare i Admin.',
    read_invoice_report: 'Krävs för att kunna skapa Privera-filen (fakturaunderlaget till Region Sörmland).',
    view_patients: 'Krävs för att över huvud taget kunna se någon patientdata.',
    export_journal: 'Krävs för att kunna exportera en patientjournal som PDF.',
    handle_auscultation: 'Krävs för att kunna paxa patienter som kräver bedömning av hjärt- och lungauskultation.',
    accounting: 'Krävs för att få tillgång till bokföringsfunktioner.',
    manage_partners: 'Krävs för att skapa och uppdatera partnerorganisationer och deras mottagningar och användare.',
    handle_certificate_patients:
      'Krävs för att hantera patienter som hänvisats till Blodtrycksdoktorn som intygspatienter.',
    handle_next_gen_patients: 'Krävs för att hantera patienter som använder NextGen-appen.',
    medical_management: 'Ger tillgång till särskilda administrativa funktioner för vårdpersonal.',
    manage_absence: 'Ger tillgång till funktioner för att hantera vårdgivares frånvaro.'
  },
  error_boundary: {
    header: 'Något gick fel :(',
    summary: 'Information om felet har vidarebefordrats till utvecklingsteamet.'
  },
  member_state: {
    default_bottom_funnel: 'Bottom funnel',
    deactivation_funnel: 'Deactivation funnel'
  },
  member_state_actions: {
    bottom_funnel_paused: 'pausades',
    resumed: 'återupptogs',
    '-': 'återupptogs'
  },
  program_timeline_view: {
    header: 'Tidslinje',
    get_programs_error: 'Kunde inte hämta program',
    no_active_program: 'Patienten har inget pågående program',
    program: 'Program',
    step: {
      completed: 'Slutförd',
      not_started: 'Ej påbörjad',
      ongoing: 'Pågående',
      started: 'Påbörjad'
    }
  },
  accounting: {
    tabs: {
      summary: 'Bokföringsrapporter',
      batches: 'Rapportera inleverans'
    },
    summary: {
      header: 'Totaler av alla konton',
      error: 'Det gick inte att hämta bokföringsdata.',
      table_header: {
        account: 'Bokföringskonto',
        name: 'Namn',
        debit: 'Debit',
        credit: 'Kredit',
        sum: 'Summa',
        ingoing_balance: 'Ing. balans',
        outgoing_balance: 'Utg. balans'
      },
      download: 'Hämta bokföringsverifikat'
    },
    batches: {
      header: 'Leveransbatcher',
      error: 'Det gick inte att hämta batcher.',
      table_header: {
        batch_number: 'Batchnummer',
        value: 'Totalt värde (SEK)',
        depreciation: 'Periodisering (antal dagar)',
        num_units: ' Antal varor (st)',
        unit_value: 'Värde per enhet (SEK)',
        date: 'Inleveransdatum'
      },
      new: 'Ny batch',
      create: 'Skapa batch',
      delivery_date: 'Inleveransdatum',
      delivery_items: 'Antal varor i leverans (st)',
      delivery_value: 'Totalt värde leverans (SEK)',
      unit_value: 'Värde per enhet: %{unitValue} SEK'
    }
  },
  manage_absence: {
    list_heading: 'Planerad frånvaro',
    create_heading: 'Lägg till frånvaro',
    edit_heading: 'Redigera frånvaro',
    absence_reason_heading: 'Anledning',
    period_heading: 'Period',
    reason: {
      vacation: 'Semester',
      sick_leave: 'Sjukskrivning',
      parental_leave: 'Föräldraledig',
      leave_of_absence: 'Tjänstledighet',
      education: 'Utbildning',
      missing: 'Anledning saknas'
    },
    caregiver_no_absence: 'Ingen frånvaro planerad.',
    no_absence_planned_during_period: 'Ingen frånvaro planerad under vald period.',
    confirm_remove_absence: 'Är du säker på att du vill ta bort frånvaron?',
    move_patients_heading: 'Flytta patienter till vårdgivare',
    add_more: '+ Lägg till fler',
    percentage: 'Procentsats',
    total_share: 'Total andel',
    remove_absence: 'Ta bort frånvaro',
    responsible_for_n_patients: '%{role} för %{numPatients} patienter*',
    num_patients: '≈ %{numPatients} patienter',
    count_notice: '* antalet kan komma att ändras innan valt startdatum.',
    absence_about_to_start: 'Frånvaron kan inte redigeras eftersom den startar om mindre än två veckor.',
    absence_ongoing: 'Frånvaron pågår.',
    random_assignment: 'Automatiskt',
    absence_not_found: 'Frånvaron hittades inte.',
    generic_error: 'Det gick inte att hämta frånvaron.'
  },
  manage_allocation: {
    heading: 'Beläggning',
    current_allocation: 'Nuvarande beläggning',
    headings: {
      caregiver: 'Vårdgivare',
      allocation: 'Aktuell beläggning',
      share: 'Andel av inkommande patienter'
    },
    num_patients: '%{numPatients} patienter',
    patients: 'patienter',
    treatment_capacity: 'Kapacitet för patienter i behandling',
    share_of_inflow: 'Andel av inkommande patienter',
    go_to_list: 'Gå till listan över beläggning'
  },
  manage_partners: {
    header: 'Partnerorganisationer',
    error: 'Kunde inte hämta partners.',
    partner_list_sub_header: 'Organisationer',
    partner_list_error: 'Kunde inte hämta listan över partnerorganisationer',
    status: {
      active: 'Aktiv',
      inactive: 'Inaktiv'
    },
    partner_overview: {
      edit_partner: 'Redigera partner',
      no_facilities: 'Inga mottagningar',
      add_facility: 'Lägg till mottagning',
      partner_services: 'Partnertjänster',
      num_facilities: '%{facilityCount} mottagningar',
      single_facility: '1 mottagning'
    },
    facilities: {
      header: 'Mottagningar',
      error: 'Kunde inte hämta mottagningar',
      new_facility: '+ Ny %{partner}-mottagning',
      create_new_header: 'Lägg till ny %{partner}-mottagning',
      edit_header: 'Redigera mottagning',
      edit: 'Redigera',
      fetch_facility_error: 'Kunde inte hämta denna mottagning.',
      num_users: '%{userCount} användare',
      num_users_global: 'som inte tillhör någon specific mottagning',
      confirm_delete: 'Är du säker på att du vill ta bort mottagningen?',
      empty_file: 'Vänligen välj en instruktions-pdf.',
      name: 'Namn på mottagningen',
      phone: 'Telefonnummer',
      address: 'Adress',
      region: 'Partnerregion',
      cost_center: 'Kostnadscenter',
      street_address_missing: 'Gatuadress saknas',
      city_missing: 'Stad saknas',
      postal_code_missing: 'Postnummer saknas',
      email: 'E-postadress',
      instructions_file: 'Instruktionsdokument',
      select_file: 'Välj fil',
      acceptable_distance: 'Acceptabelt avstånd (km)',
      acceptable_distance_no_suffix: 'Acceptabelt avstånd',
      cancellation_policy: 'Avbokningspolicy',
      status: 'Status',
      services: 'Tjänster',
      add_user: 'Lägg till användare',
      button: {
        remove: 'Ta bort mottagning',
        add: 'Lägg till mottagning',
        update: 'Uppdatera mottagning'
      },
      activation_codes: {
        title: 'Aktiveringskoder',
        description: 'Används för att konfigurera Kiosk-enheter.',
        status: {
          used: 'Använd',
          revoked: 'Utgått'
        },
        action: {
          generate: 'Generera ny kod',
          send: 'Skicka via e-post',
          revoke: 'Annullera'
        },
        no_codes: 'Inga aktiveringskoder har genererats.'
      }
    },
    add_new_partner: {
      header: 'Lägg till partnerorganisation',
      header_edit: 'Redigera partnerorganisation',
      confirm_delete: 'Skriv namnet på partnerorganisationen för att bekräfta borttagningen.',
      confirm_delete_header: 'Bekräfta borttagning',
      partner_name: 'Partnerorganisationens namn',
      sms_content_1: 'Innehåll sms #1',
      sms_content_2: 'Innehåll sms #2 (påminnelse)',
      handles_certificate_patients: 'Hänvisar intygspatienter',
      fetch_error: 'Kunde inte hämta partnerorganisationen.',
      button: {
        remove: 'Ta bort partner',
        add: 'Skapa partnerorganisation',
        update: 'Uppdatera partnerorganisation'
      },
      kiosk: {
        images_header: 'Kiosklogotyper',
        start_image: 'Startskärm',
        footer_image: 'Footer',
        select_start_image: 'Välj startskärmsbild',
        select_new_start_image: 'Välj ny startskärmsbild',
        select_footer_image: 'Välj footerbild',
        select_new_footer_image: 'Välj ny footerbild'
      },
      select_image: 'Välj bild',
      web_screening: {
        partner_logo: 'Partnerorganisationens logotyp',
        start_screen_image: 'Bild',
        low_risk_results_image_sv: 'Bild (svenska)',
        low_risk_results_image_en: 'Bild (engelska)',
        heading_sv: 'Rubrik (svenska)',
        heading_en: 'Rubrik (engelska)',
        text_sv: 'Text (svenska)',
        text_en: 'Text (engelska)',
        button_text_sv: 'Knapptext (svenska)',
        button_text_en: 'Knapptext (engelska)',
        low_risk_cta_url_sv: 'URL (svensk sida)',
        low_risk_cta_url_en: 'URL (engelsk sida)',
        start_screen: 'Startskärm',
        main_cta: 'CTA #1 (nedladdning)',
        secondary_cta: 'CTA #2 (låg risk)',
        results_high: 'Resultatskärm (hög risk)',
        results_low: 'Resultatskärm (låg risk)',
        url_change_confirmation:
          'Är du säker på att du vill ändra URL? Alla existerande länkar med "/%{path}" kommer att sluta fungera.'
      }
    },
    edit_partner: {
      header: 'Redigera %{name}'
    },
    users: {
      header: 'Användare',
      export: 'Exportera användare',
      header_global: 'Övergripande partneranvändare',
      error: 'Kunde inte hämta användarlista.',
      fetch_user_error: 'Det gick inte att hämta användaren.',
      confirm_delete: 'Är du säker på att du vill ta bort användaren?',
      given_name: 'Förnamn',
      family_name: 'Efternamn',
      email: 'E-postadress',
      services: 'Rättigheter',
      title: 'Yrkesroll',
      select_title: 'Välj yrkesroll',
      recommends_certificate_patients: 'Hänvisar intygspatienter',
      titles: {
        nurse: 'Sjuksköterska',
        doctor: 'Läkare',
        administrator: 'Administratör',
        physiotherapist: 'Fysioterapuet',
        naprapath: 'Naprapat',
        chiropractor: 'Kiropraktor',
        assistantNurse: 'Undersköterska',
        other: 'Annat'
      },
      button: {
        remove: 'Ta bort användare',
        add: 'Skapa användare',
        update: 'Uppdatera användare'
      }
    },
    add_user: {
      header: 'Lägg till användare',
      header_edit: 'Redigera användare'
    },
    service: {
      examination: 'Utredning analogt stetoskop',
      auscultation: 'Utredning digitalt stetoskop',
      recommendation: 'Hänvisning av patient',
      admin: 'Administratör',
      kiosk: 'Kioskmätning',
      webScreening: 'Blodtryckstest'
    },
    partner_services: {
      select_services: 'Välj tjänster',
      all_selected: 'Alla tjänster valda',
      available_services: 'Tillgängliga tjänster'
    },
    breadcrumb: {
      create_partner: 'Skapa partner',
      edit_partner: 'Redigera partner',
      facility: 'Mottagningsöversikt',
      create_facility: 'Skapa mottagning'
    },
    user_property: {
      auscultation_onboarding: {
        unsubmitted: 'Provinspelning ej inskickad',
        pending: 'Provinspelning inväntar bedömning',
        rejected: 'Provinspelning underkänd',
        accepted: 'Provinspelning godkänd'
      }
    }
  },
  server_error: {
    db_access_error: 'Databasfel',
    internal: 'Internt serverfel',
    unknown_user: 'Okänd användare',
    insufficient_privileges: 'Användaren saknar behörighet',
    authentication_error: 'Autentiseringsfel',
    user_canceled_login: 'Användaren avbröt inloggningen',
    login_already_in_process: 'Inloggning redan påbörjad',
    external_service_timeout: 'Extern tjänst tog för lång tid att svara',
    not_implemented: 'Inte implementerad',
    invalid_data_posted: 'Data skickades i felaktigt format',
    missing_data: 'Data saknas',
    id_already_exists: 'ID existerar redan',
    fallback: 'Okänt fel - kontakta teknisk support',
    connectivity: 'Kunde inte kontakta servern. Kontrollera internetanslutningen.',
    unauthorized: 'Saknar behörighet'
  }
};
